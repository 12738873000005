import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit'
import { Outlet } from 'application/domain/entity/outlet/Outlet'
import _ from 'lodash'
import { AuthUserType } from 'types/UserType'

type AppModeType = 'food' | 'booking'

type UserState = {
  user: AuthUserType
  token: string | null
  outlet: Outlet | null
  appMode: AppModeType
  needCheckProducts: boolean
}

const initialState: UserState = {
  user: null,
  token: '',
  outlet: null,
  appMode: 'food',
  needCheckProducts: localStorage.getItem('need_check_products') === 'true',
}

export const userReducer = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    setUserAction: (state, { payload: user }: PayloadAction<AuthUserType>) => {
      state.user = user
    },
    setUserTokenAction: (state, { payload: token }: PayloadAction<string | null>) => {
      state.token = token
    },
    setSelectedOutletAction: (state, { payload: outlet }: PayloadAction<Outlet | null>) => {
      state.outlet = outlet
    },
    setAppModeAction: (state, { payload: mode }: PayloadAction<AppModeType>) => {
      state.appMode = mode
      localStorage.setItem('appMode', mode)
    },
    setNeedCheckProductsAction: (state, { payload: value }: PayloadAction<boolean>) => {
      state.needCheckProducts = value
      if (value) {
        localStorage.setItem('need_check_products', 'true')
      } else {
        localStorage.removeItem('need_check_products')
      }
    },
  },
})

const {
  setUserAction,
  setUserTokenAction,
  setSelectedOutletAction,
  setAppModeAction,
  setNeedCheckProductsAction,
} = userReducer.actions

export const setUser = (user: AuthUserType) => (dispatch: Dispatch) => dispatch(setUserAction(user))
export const setToken = (token: string | null) => (dispatch: Dispatch) =>
  dispatch(setUserTokenAction(token))

export const setOutlet = (outlet: Outlet | null) => (dispatch: Dispatch) =>
  dispatch(setSelectedOutletAction(outlet))

export const setAppMode = (mode: AppModeType) => (dispatch: Dispatch) =>
  dispatch(setAppModeAction(mode))

export const setNeedCheckProducts = (value: boolean) => (dispatch: Dispatch) =>
  dispatch(setNeedCheckProductsAction(value))

export default userReducer.reducer
